<template>
  <div>
    <add-single-scooter />
    <AssignVehicle
      v-if="showAssignVehicleModal"
      :sunpodId="currentSunpodId"
      v-on:close="onAssignVehicleModalClose"
    />

    <VehicleIndex
      :fst-id="`sunpod-vehicles`"
      :page-title="$t('components.pods.sunpods.details.steps.vehicles.headline')"
      :endpoint="endpoint"
      :qso="qso"
      :isAddAnother="true"
      :tableHeaders="tableHeaders"
      @add-another="assignVehicle"
      @edit="edit"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils/EventBus'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import { VehicleIndex } from '@/composites/vehicle'
export default {
  name: 'ViewSunpodVehicles',
  components: {
    AddSingleScooter: () => import('@/views/scooter/AddSingleScooter.vue'),
    AssignVehicle: () => import('@/views/sunpods/AssignVehicle.vue'),
    VehicleIndex,
  },

  data() {
    return {
      is_edit: false,
      showAssignVehicleModal: false,
      currentSunpodId: null,
      qso: { append: '', prepend: '' },
      endpoint: null,
      tableHeaders: [
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.name'), width: '10%', sort: null },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.battery'), width: '10%', sort: 'lock__power_level' },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.imei'), width: '10%', sort: null },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.qrCode'), width: '10%', sort: null },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.lastConnected'), width: '13%', sort: null },
        {  text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.lastLocated'), width: '13%', sort: null },
        {  text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.flags'), width: '12%', sort: null },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.heartBeat'), width: '8%', sort: null },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.lastLocation'), width: '9%', sort: null },
        { text: this.$t('components.pods.sunpods.details.steps.vehicles.table.columns.actions'), width: '5%', sort: null },
      ],
      filterOptions: [
        {
          key: 'bike_category',
          type: 'checkbox',
          input: [
            { text: 'Scooters', value: 'P' },
            { text: 'EBikes', value: 'E' },
            { text: 'Bikes', value: 'S' },
          ],
          title: 'Category',
        },
        {
          key: 'status_flag',
          type: 'checkbox',
          input: [
            { text: 'On Rent', value: 'on_ride' },
            { text: 'On Reservation', value: 'on_reservation' },
            { text: 'On Parking', value: 'on_parking' },
            { text: 'On Illegaly Parked', value: 'illegally_parking' },
            { text: 'On Charging', value: 'on_charging' },
            { text: 'On Idle', value: 'idle' },
            { text: 'Damaged', value: 'damaged' },
          ],
          title: 'Status Flags',
        },

        {
          key: 'general_flag',
          type: 'checkbox',
          input: [
            { text: 'Missing', value: 'missing' },
            { text: 'IoT Fault', value: 'iot_fault' },
            { text: 'GeoFence Alert', value: 'geofence_alert' },
          ],
          title: 'General Flags',
        },

        {
          key: 'ops_flag',
          type: 'checkbox',
          input: [
            { text: 'Rebalance', value: 'rebalance' },
            { text: 'Charging Pick', value: 'charging_pick' },
            { text: 'Maintenance', value: 'maintenance' },
            { text: 'Swap Battery', value: 'swap_battery' },
          ],
          title: 'Operational Flags',
        },

        {
          key: 'active',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'true' },
            { text: 'Inactive', value: 'false' },
          ],
          title: 'Connectivity',
        },

        {
          key: 'locked',
          type: 'checkbox',
          input: [
            { text: 'Locked', value: 'true' },
            { text: 'Unlocked', value: 'false' },
          ],
          title: 'Lock',
        },

        {
          key: 'power',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 100,
            minQs: 'min_power_level', // query string for min value
            maxQs: 'max_power_level', // query string for max value
          },
          title: 'Battery',
        },
      ],
    }
  },
  created() {
    this.generateEndPoint()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.vehicle.index() + `?sunpod=${this.$route.params.id}&`
    },
    assignVehicle() {
      this.showAssignVehicleModal = true
      this.currentSunpodId = this.$route.params.id
    },
    onAssignVehicleModalClose() {
      this.showAssignVehicleModal = false
    },

    edit({ vehicle }) {
      console.log(vehicle)
      this.is_edit = true
      EventBus.$emit(SingleScooterConfig.events.editingData, vehicle)
      dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    },
  },
}
</script>
